<!--
 * @Descripttion: 简要描述 ——
 * @Author: Shuangshuang Song
 * @Date: 2020-04-01 11:16:50
 * @LastEditTime: 2020-04-27 18:15:05
 -->
<template>
  <div class="Jepaas-detail-orderList background-F5F5F5">
    <el-backtop />
    <div
      class="box_top"
    >
      <Header />
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Header from '@/components/Header';

export default {
  components: {
    Header,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  updated() {
  },
  methods: {},
};
</script>

<style lang="less" scoped>
  @import "../../../style/shop/util";
</style>
<style lang="less">
  ::-webkit-scrollbar {
    display: none;
  }
  .Jepaas-detail-orderList {
    position: relative;
    width: 100%;
    min-height: 100vh;
    .el-backtop {
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      z-index: 10;
      border-radius: 4px;
      width: 52px;
      height: 52px;
      right: 2% !important;
      bottom: 43px !important;
      font-size: 24px;
      color: #ff3041;
      &:hover {
        background: #ff3041;
        > i {
          color: #ffffff;
        }
      }
    }
    .box_top {
      width: 100%;
      height: 80px;
      background: linear-gradient(
              270deg,
              rgba(252, 103, 81, 1) 0%,
              rgba(246, 71, 77, 1) 100%
      );
    }
  }
</style>
